/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 $blue: #007bff;
 $indigo: #6610f2;
 $purple: #6f42c1;
 $pink: #e83e8c;
 $red: #dc3545;
 $orange: #fd7e14;
 $yellow: #ffc107;
 $green: #28a745;
 $teal: #20c997;
 $cyan: #17a2b8;
 $white: #fff;
 $gray: #6c757d;
 $gray-dark: #343a40;
 $primary: #007bff;
 $secondary: #6c757d;
 $success: #28a745;
 $info: #17a2b8;
 $warning: #ffc107;
 $danger: #dc3545;
 $light: #f8f9fa;
 $dark: #343a40;
 $breakpoint-xs: 0;
 $breakpoint-sm: 576px;
 $breakpoint-md: 768px;
 $breakpoint-lg: 992px;
 $breakpoint-xl: 1200px;
 $font-family-sans-serif: Roboto-Regular;
 $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
 $aeso-background: #F8FBFF;
 $aeso-background-2: #ffffff;
 $aeso-background-3: #F4F4F4;
 $aeso-primary-font-color: #ffffff;
 $aeso-secondary-font-color: #142e00;
 $aeso-primary-color: #003D75;
 $aeso-primary-color-disable: #666666;
 $aeso-primary-color-disable-1: #C4C4C4;
 $aeso-bc-color: #5E0F8B; //F68E5F
 $aeso-matl-color: #FF8C00; //8A7CFF
 $aeso-sk-color: #00B9AE;
 $aeso-bc-matl-color: #16b8f3;
 $aeso-system-color: #EF476F;
 $aeso-border-color:#979797;
 $aeso-border-color-2:#D7D7D7;
 $aeso-selected-bg-color:#E9F4FF;
 $aeso-primary-font:14px;
 $aeso-bc-background:#D7D3FB;
 $aeso-matl-background:#F8D9CB;
 $aeso-sk-background:#AEE6E2;
 $aeso-bc-matl-background:#D7F0F8;
 $aeso-system-background:#F6C3CF;
 $aeso-error-text-color:#FF0000;
 $aeso-grid-bg: #003a75;
 $aeso-background-4:#efefef;
 $aeso-notification: #c4fffb;//e2e2e2#;



:export {
  #{'aesoBcColor'}:  $aeso-bc-background;
  #{'aesoMatlColor'}:  $aeso-matl-background;
  #{'aesoSkColor'}:  $aeso-sk-background;
  #{'aesoBcBackground'}:$aeso-bc-color;
  #{'aesoMatlBackground'}:$aeso-matl-color;
  #{'aesoSkBackground'}:$aeso-sk-color;
  
  #{'aesoBcMatlColor'}:$aeso-bc-matl-color;
  #{'aesoBcMatlBackground'}:$aeso-bc-matl-background;
}