.App {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.side-bar {
  display: flex;
  flex-direction: column;
  width: 15vw;
  height: 100%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
}

.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: whitesmoke;
  background-color: #2d383a;
  border-bottom: 5px solid crimson;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 36pt;
}

.side-bar-body {
  padding-left: 10px;
}

#api-data {
  width: 90vw;
}

.swagger-ui .info {
  margin: 40px 5px !important;
}

.swagger-ui .scheme-container {
  padding: 15px 5px !important;
}

.ml-40{
  margin-left: 40px !important;
}

.register-link-api{
  margin-top: 30px;
  margin-left: 70px;
}


.App {
  display: flex;
  flex-direction: row;
}



.side-bar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: whitesmoke;
  background-color: #2d383a;
}

.side-bar-header h1 {
  margin: 0;
  font-size: 36pt;
}

.side-bar-body {
  padding-left: 10px;
}

#api-data {
  width: 100%;
  padding-bottom: 50px;
}

.api-link {
  margin-top: 10px;
  font-size: 12pt;
  transition: all .1s ease;
  padding-left: 10px;
  color: #ffffff;
}

.api-header-sidebar {
  margin-top: 10px;
  font-size: 12pt;
  transition: all .1s ease;
  color: #ffffff;
  font-weight: bold;
  padding-left: 5px;
  border-bottom: 1px solid #ffffff;
  transition: all .1s ease;
}

#placeholder{
    display: none !important;
}



.api-link:hover {
  cursor: pointer;
  border-left: 5px solid crimson;
  transition: all .1s ease;
}

.api-link::selection {
  cursor: pointer;
  border-left: 5px solid crimson;
  transition: all .1s ease;
}

.swagger-ui{
  font-family: 'Roboto-Medium' !important;
}

.swagger-ui .info li, .swagger-ui .info p, .swagger-ui .info table{
  font-family: 'Roboto-Medium' !important;
}

.swagger-ui .wrapper{
  max-width: inherit !important;
}

.info__contact {
  display: none;
}

.servers {
  display: none;
}

.servers-title{
  display: none;
}